import React from 'react';
import { bindings, hook } from '@vl/redata';
import _ from 'lodash';
import useRoute from '@vl/hooks/useGbRouteDe';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { getClient, gql } from '@vl/mod-clients/hasuraApp';
import usePromiseSource from '@vl/hooks/usePromiseSource';
import useLoadingModel from '@vl/hooks/useLoadingModel';
import fbFnsClient from '@vl/mod-clients/fibGatsbyFns';
import useObservableSource from '@vl/hooks/useObservableSource';
import useSubjectSource from '@vl/hooks/useSubjectSource';

const bindData = bindings({
  loader: {
    rules: [
      [
        'data',
        {
          data: {
            loadingModel: hook(useLoadingModel(() => ({ fetchData: true }))),
            subscription_plans_loading: hook((ctx) => {
              const [version, $version] = React.useState(0);
              return {
                version,
                $version,
                reload: () => $version(version + 1),
              };
            }),
            subscription_plans: hook((ctx) => {
              const account_id = ctx.apply('accountModel.getAccountId');

              const condition = {
                where: {
                  account_id: { _eq: account_id },
                },
              };
              const data = usePromiseSource(
                async () => {
                  try {
                    if (!account_id) return null;
                    ctx.apply('loadingModel.setLoading', 'fetchData');
                    const client = await getClient();
                    const resData = await client.request(
                      gql`
                        query b2b_subscription_plan {
                          b2b_subscription_plan (
                            order_by: { display_order: asc_nulls_last }
                          ){
                            name
                            description: name
                            price
                            plan_id
                            management: plan_id
                            management_course_qos: price
                            management_student_qos: price
                            currency_iso_code
                            display_order
                          }
                        }
                      `,
                      {}
                    );
                    ctx.apply('loadingModel.clearLoading', 'fetchData');
                    return resData;
                  } catch (err) {
                    console.log('error', err);
                  }
                },
                null,
                [account_id, ctx.get('subscription_plans_loading.version')]
              );

              const sub = useObservableSource(
                async () => {
                  try {
                    if (!account_id) return null;
                    const client = await getClient();
                    const data = await client.subscribe(
                      gql`
                        subscription b2b_subscription_plan($where: b2b_account_billing_bool_exp = {}) {
                          b2b_account_billing(where: $where) {
                            id
                            payment_method
                            information
                            subscription {
                              id
                              plan_id
                            }
                          }
                        }
                      `,
                      { ...condition }
                    );
                    return { observe: () => data };
                  } catch (err) {
                    console.log('error', err);
                  }
                },
                null,
                [account_id]
              );
              const subData = useSubjectSource(sub);

              const items = _.get(data, 'b2b_subscription_plan');
              const plans = _.map(items, (data) => {
                return {
                  // plan details
                  ..._.pick(data, ['name', 'description', 'price', 'plan_id']),
                  // management details
                  management: '',
                  ..._.pick(data, [
                    'management_course_qos',
                    'management_student_qos',
                  ]),
                  data,
                };
              });
              const subscription = _.get(subData, 'data.b2b_account_billing.0.subscription');
              const billing = _.get(subData, 'data.b2b_account_billing.0');
              return {
                plans,
                subscription,
                billing,
              };
            }),
          }
        }
      ]
    ]
  },
  pageContainer: {
    rules: [
      [
        'data',
        {
          data: {
            currentPlan: hook((ctx) => {
              const route = useRoute();
              const routeParams = _.get(route, 'params', {});
              const plan_id = _.get(routeParams, 'plan_id', 'unitz_plan_free');
              const plans = ctx.get('subscription_plans.plans');
              const plansByPlanId = _.keyBy(plans, 'plan_id');
              const currentPlan = plansByPlanId[plan_id];
              return currentPlan;
            }),
            isCurrentPlan: hook((ctx) => {
              const activePlanId = ctx.get('subscription_plans.subscription.plan_id') || 'unitz_plan_free';
              const currentPlanId = ctx.get('currentPlan.plan_id');
              const isCurrentPlan = activePlanId === currentPlanId;
              return isCurrentPlan;
            }),

            form: hook((ctx) => {
              const route = useRoute();
              const form = {};
              const isCurrentPlan = ctx.get('isCurrentPlan');
              const payment_method = ctx.get('subscription_plans.billing.payment_method');
              const billing_information = ctx.get('subscription_plans.billing.information');

              _.assign(form, {
                canSubmit: !isCurrentPlan && payment_method && billing_information,
                handleSubmit: async () => {
                  const userId = ctx.apply('authModel.getUserId');
                  const account_id = ctx.apply('accountModel.getAccountId');
                  const routeParams = _.get(route, 'params', {});
                  const plan_id = _.get(routeParams, 'plan_id', 'unitz_plan_free');
                  const res = await fbFnsClient.getClient().post('payment-createSubscription', {
                    user_id: userId,
                    account_id,
                    plan_id,
                  });
                  console.log('res', res);
                  ctx.apply('subscription_plans_loading.reload');
                }
              });

              return form;
            }),
          }
        }
      ]
    ]
  },
});

export default bindData;
