import React from 'react';
import _ from 'lodash';
import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import { Table, Card, Input } from 'antd';
import styled from 'styled-components';
import LoadingCheckWrapper from '@uz/unitz-components-web/LoadingCheckWrapper';
import PaymentMethod from '@uz/unitz-tool-components/B2BBilling/PaymentMethod';
import BillingInformation from '@uz/unitz-tool-components/B2BBilling/BillingInformation';
import { Button } from '@uz/unitz-components-web/Button';


const Index = () => {
  return (
    <LoadingCheckWrapper>
      <DIV className="pageContainer">
        {/* <Card title={ctx.apply('i18n.rt', 'Plan details')}>

        </Card> */}
        <Card title={ctx.apply('i18n.rt', 'Payment')}>
          <div className="flex justify-between">
            <div>
              <strong className="font-bold text-lg pr-1">
                {ctx.apply('i18n.rt', 'Total amount')}
              </strong>
            </div>
            <div>
              <strong className="font-bold text-lg pr-1">{`${ctx.get('currentPlan.price', '')}`}</strong>
              {`${ctx.get('currentPlan.data.currency_iso_code')}/${ctx.apply('i18n.rt', 'month')}`}
            </div>
          </div>
          <div className="flex justify-between">
            <div>
              {ctx.get('currentPlan.name', '')}
            </div>
            <div>
              <span className="pr-1">{`${ctx.get('currentPlan.price', '')}`}</span>
              {`${ctx.get('currentPlan.data.currency_iso_code')}/${ctx.apply('i18n.rt', 'month')}`}
            </div>
          </div>
        </Card>
        <BillingInformation />
        <PaymentMethod />
        <div className="p-4">
          <Button
            onClick={ctx.get('form.handleSubmit')}
            type="primary"
            block
            disabled={!ctx.get('form.canSubmit')}
          >
            {ctx.get('isCurrentPlan') ?
              `${ctx.apply('i18n.rt', 'Current plan')}` :
              `${ctx.apply('i18n.rt', 'Upgrade to')} ${ctx.get('currentPlan.name')}`
            }
          </Button>
        </div>
      </DIV>
    </LoadingCheckWrapper>
  );
};

export default displayName(Index);
